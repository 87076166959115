import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Course } from '@app/_models';
import { CoursesService } from '@app/_services';

@Component({
  selector: 'app-search-navbar',
  templateUrl: './search-navbar.component.html',
  styleUrls: ['./search-navbar.component.css']
})
export class SearchNavbarComponent {
  @Output() searchEvent = new EventEmitter<string>();
  @Output() suggestionSelected = new EventEmitter<void>();
  @ViewChild('searchInput') searchInput!: ElementRef;
  @Input() isSmallScreen: boolean = false;

  searchResults: Course[] = [];
  showSuggestions: boolean = false;

  constructor(private coursesService: CoursesService, private router: Router) {}

  // Listen for clicks on the document
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    // Check if the click is outside the search container
    if (!this.isClickInsideSearchContainer(event)) {
      this.clearSearchInput();
      this.showSuggestions = false;
    }
  }

  // Helper function to check if the click is inside the search container
  private isClickInsideSearchContainer(event: MouseEvent): boolean {
    const searchContainer = this.searchInput.nativeElement.closest('.search-container');
    return searchContainer?.contains(event.target as Node);
  }

  onSearch(searchTerm: string): void {
    this.searchEvent.emit(searchTerm);
    this.showSuggestions = false;
  }

  onSearchInput(searchTerm: string): void {
    if (searchTerm.length >= 2) {
      this.coursesService.searchCourses(searchTerm).subscribe((courses) => {
        this.searchResults = courses;
        this.showSuggestions = true;
      });
    } else {
      this.searchResults = [];
      this.showSuggestions = false;
    }
  }

  selectSuggestion(course: Course): void {
    const currentUrl = this.router.url;
    const targetUrl = `/courses/details/${course._id}`;
    if (currentUrl === targetUrl) {
      // If already on the same course page, force a reload
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([targetUrl]);
      });
    } else {
      // Navigate to the target course page
      this.router.navigate([targetUrl]);
    }
    this.showSuggestions = false;
    this.suggestionSelected.emit();
  }

  clearSearchInput(): void {
    this.searchInput.nativeElement.value = '';
  }
}
