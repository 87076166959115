<!-- Carousel -->
<div id="heroCarousel" class="carousel slide" data-bs-ride="carousel">
    <!-- Carousel Indicators -->
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
  
    <!-- Carousel Slides -->
    <div class="carousel-inner">
      <!-- Slide 1 -->
      <div class="carousel-item active">
        <img src="assets/img/rsz_about_us.jpg" class="d-block w-100" alt="Slide 1">
        <div class="carousel-caption">
          <h2>Unlock Your Potential</h2>
          <p>Learn in-demand skills from industry experts.</p>
          <a routerLink="/courses/all" class="btn btn-primary">Explore Courses</a>
        </div>
      </div>
  
      <!-- Slide 2 -->
      <div class="carousel-item">
        <img src="assets/img/carousel-1.jpg" class="d-block w-100" alt="Slide 2">
        <div class="carousel-caption">
          <h2>Boost Your Career</h2>
          <p>Get certified and stand out in the job market.</p>
          <a routerLink="/courses/all" class="btn btn-primary">Start Learning</a>
        </div>
      </div>
  
      <!-- Slide 3 -->
      <div class="carousel-item">
        <img src="assets/img/carousel-2.jpg" class="d-block w-100" alt="Slide 3">
        <div class="carousel-caption">
          <h2>Earn Certificates</h2>
          <p>Stand out in your field with recognized certifications.</p>
          <a routerLink="/courses/all" class="btn btn-primary">Get Certified</a>
        </div>
      </div>
    </div>
  
    <!-- Carousel Controls -->
    <button class="carousel-control-prev" type="button" data-bs-target="#heroCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#heroCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>