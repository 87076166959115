import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Account, Course, Role } from '@app/_models';
import { AccountService, CategoriesService, CoursesService, DiscountTimerService, ScreenSizeService } from '@app/_services';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Category } from '@app/_models/category';

/**
 * NavbarComponent
 * Manages the navigation bar, including account management, language switching, 
 * search functionality, responsive behavior, and discount timer display.
 */
@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.css'],
})
export class NavbarComponent {
  // Template references
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('navbarCollapse') navbarCollapse!: ElementRef;


  // Role enum for use in templates
  Role = Role;

  // Account data of the logged-in user
  account?: Account | null;

  // Properties for search functionality
  searchResults: Course[] = [];
  showSuggestions: boolean = false;

  // Responsive behavior
  isSmallScreen: boolean = false;

  // Discount timer observable
  timeLeft$: Observable<number>;

  // Current language for translation
  currentLanguage: string = 'fr';

  // Add a property to control the visibility of the timer
  showTimer: boolean = true;

  categories : Category[] = []

  timeLeftValue: number | null = null;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private coursesService: CoursesService,
    private screenSizeService: ScreenSizeService,
    private discountTimerService: DiscountTimerService,
    private translate: TranslateService,
    private categoriesService: CategoriesService
  ) {
    this.timeLeft$ = this.discountTimerService.timeLeft;
  }

  /**
   * Lifecycle hook - OnInit
   * Subscribes to account and screen size changes, sets the initial language.
   */
  ngOnInit() {
    this.accountService.account.subscribe((x) => (this.account = x));
    this.screenSizeService.isSmallScreen().subscribe((isSmall) => {
      this.isSmallScreen = isSmall;
    });

    // Subscribe to the timeLeft$ observable to update timeLeftValue
    this.timeLeft$.subscribe((timeLeft) => {
      this.timeLeftValue = timeLeft;
    });

    // Load saved language or default to French and update the current language
    const savedLanguage = localStorage.getItem('language') || 'fr';
    console.log('Loaded language:', savedLanguage); // Debugging
    this.translate.use(savedLanguage);
    this.currentLanguage = savedLanguage; // Update currentLanguage

    // getting categories
    this.categoriesService.getAll().subscribe(categories => {
      this.categories = categories;
    });
  }

  selectCategory(categoryId: string): void {
    this.router.navigate([`/courses/category/${categoryId}`]);
  }

  /**
   * Switches the application language and stores the preference in localStorage.
   * @param language - The new language to switch to.
   */
  switchLanguage(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.currentLanguage = lang; // Update currentLanguage
  }

  /**
 * Hides the timer by setting showTimer to false.
 */
  hideTimer(): void {
    this.showTimer = false;
  }

  /**
   * Logs the user out by invoking the account service.
   */
  logout() {
    this.accountService.logout();
  }

  /**
   * Handles the search submission and navigates to the courses page.
   * @param searchTerm - The term entered in the search input.
   */
  onSearch(searchTerm: string) {
    this.router.navigate(['/courses'], { queryParams: { search: searchTerm } });
    this.showSuggestions = false;
    this.closeNavbar();
  }

  /**
   * Fetches and displays search suggestions based on input.
   * @param searchTerm - The partial search term entered by the user.
   */
  onSearchInput(searchTerm: string) {
    if (searchTerm.length >= 2) {
      this.coursesService.searchCourses(searchTerm).subscribe((courses) => {
        this.searchResults = courses;
        this.showSuggestions = true;
      });
    } else {
      this.searchResults = [];
      this.showSuggestions = false;
    }
  }

  /**
   * Handles selection of a search suggestion and navigates to the course details.
   * @param course - The selected course.
   */
  selectSuggestion(course: Course) {
    this.closeNavbar();
    this.router.navigate([`/courses/details/${course._id}`]);
    this.showSuggestions = false;
  }

  /**
   * Listens for clicks outside the search container to close suggestions.
   * Also closes the navbar on small screens if clicked outside.
   * @param event - The click event.
   */
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const target = event.target as HTMLElement;

    // Close search suggestions if clicked outside the search container
    if (!target.closest('.search-container')) {
      this.showSuggestions = false;
      this.clearSearchInput();
    }

    // Close the navbar on small screens if clicked outside
    if (this.isSmallScreen && !target.closest('.navbar') && !target.closest('.navbar-toggler')) {
      this.closeNavbar();
    }
  }

  /**
   * Closes the responsive navbar if open.
   */
  closeNavbar() {
    if (this.navbarCollapse && this.navbarCollapse.nativeElement) {
      const navbar = this.navbarCollapse.nativeElement;
      if (navbar.classList.contains('show')) {
        navbar.classList.remove('show');
      }
    } else {
      console.warn('Navbar collapse element is not available.');
    }
  }

  /**
   * Clears the search input field.
   */
  clearSearchInput() {
    this.searchInput.nativeElement.value = '';
  }

  /**
   * Prevents event propagation to the document click listener.
   * @param event - The click event to stop.
   */
  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  /**
   * Formats the time left for the discount timer into a human-readable format.
   * @param ms - Time in milliseconds.
   * @returns The formatted time string.
   */
  formatTime(ms: number): string {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  }
}