import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-timer-navbar',
  templateUrl: './timer-navbar.component.html',
  styleUrls: ['./timer-navbar.component.css']
})
export class TimerNavbarComponent {
  @Input() showTimer: boolean = true;
  @Input() timeLeftValue: number | null = null;
  @Output() hideTimerEvent = new EventEmitter<void>();


  hideTimer(): void {
    this.hideTimerEvent.emit();
  }

  formatTime(ms: number): string {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  }
}
