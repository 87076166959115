<div class="timer text-center" *ngIf="showTimer && timeLeftValue !== null">
  <button class="close-btn" (click)="hideTimer()">×</button>
  <p>
    <strong>{{ 'Timer.ExclusiveDeal' | translate }} </strong>
    <span [innerHTML]="'Timer.DiscountDetails' | translate"></span>
    <br />
    <span [innerHTML]="'Timer.UrgencyMessage' | translate:{time: formatTime(timeLeftValue)}"></span>
  </p>
</div>



<ng-container *ngIf="isSmallScreen; else normalScreen">
  <nav class="navbar navbar-expand-md sticky-top bg-white shadow-sm">
    <div class="container-fluid">
      <!--  Button -->
      <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#bsbNavbar"
        aria-controls="bsbNavbar" aria-label="Toggle Navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- branding -->
      <a class="navbar-brand" routerLink="/">
        <div class="d-flex align-items-center">
          <img [src]="'assets/img/logo_11.png'" class="bsb-tpl-logo" alt="iPro">hello
        </div>
      </a>

      <!-- links -->
      <div class="collapse navbar-collapse justify-content-center" id="bsbNavbar" #navbarCollapse>
        <div class="d-flex flex-column align-items-center w-100">
          <ul class="navbar-nav bsb-dropdown-menu-responsive mb-2">
            <li class="nav-item">
              <a routerLink="/" class="nav-link align-items-center styled-link" (click)="closeNavbar()">{{
                'Navbar.Home' | translate }}</a>
            </li>
            <li class="nav-item">
              <a routerLink="courses/all" class="nav-link d-flex align-items-center styled-link"
                (click)="closeNavbar()">{{ 'Navbar.Courses' | translate }}</a>
            </li>
            <li class="nav-item">
              <a routerLink="courses/mycourses" class="nav-link d-flex align-items-center styled-link" *ngIf="account"
                (click)="closeNavbar()">{{ 'Navbar.MyLearning' | translate }}</a>
            </li>
            <li class="nav-item">
              <a routerLink="courses/panel" class="nav-link align-items-center styled-link"><i
                  class="bi bi-cart"></i></a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                {{ 'Navbar.Explore'| translate }}
              </a>
              <ul class="dropdown-menu">
                <li *ngFor="let category of categories">
                  <a class="dropdown-item" [routerLink]="['/courses/category', category._id]"
                    (click)="selectCategory(category._id)">
                    {{ category.name }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <!-- Search Bar -->
          <div class="search-container ms-3 mb-2" (click)="stopPropagation($event)">
            <div class="search-box">
              <form (ngSubmit)="onSearch(searchInput.value)">
                <button class="btn-search" type="submit"><i class="bi bi-search"></i></button>
                <input #searchInput type="search" class="input-search-small"
                  placeholder="{{ 'Navbar.Search' | translate }}" (input)="onSearchInput(searchInput.value)">
              </form>
            </div>

            <!-- Suggestions List -->
            <div *ngIf="showSuggestions && searchResults.length" class="suggestions-list">
              <ul class="list-group">
                <li *ngFor="let course of searchResults" class="list-group-item" (click)="selectSuggestion(course)">
                  {{ course.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- account Section -->
      <div class="navbar-profile"> 

        <ng-container *ngIf="account; else guest">
          <div class="nav-item dropdown">
            <a class="nav-link dropdown-toggle bsb-dropdown-toggle-caret-disable" href="#" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img [src]="account.image || 'assets/img/pdp.jpg'" width="50" height="50" class="rounded-circle"
                style="object-fit: cover;" alt="Profile Pic">
            </a>

            <ul class="dropdown-menu dropdown-menu-end w-100 custom-dropdown-menu">
              <li>
                <h6 class="dropdown-header fs-7 text-center">{{'Navbar.Welcome' | translate}}, {{account.firstName}}
                </h6>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a routerLink="/profile" class="dropdown-item" aria-current="true">
                  <div class="row g-0 align-items-center">
                    <div class="col-3">
                      <img [src]="account.image || 'assets/img/pdp.jpg'" width="55" height="55" class="rounded-circle"
                        style="object-fit: cover;" alt="profile picture">
                    </div>
                    <div class="col-9">
                      <div class="ps-3">
                        <div class="text-secondary ms-1 mt-1 fs-7">{{ account.username }}</div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a class="dropdown-item" routerLink="/profile">
                  <span>
                    <i class="bi bi-person-fill me-2"></i>
                    <span class="fs-7">{{'Navbar.ViewProfile' | translate}}</span>
                  </span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="courses/certificate">
                  <span>
                    <i class="bi bi-award me-2"></i>
                    <span class="fs-7">{{'Navbar.Certificates'| translate}}</span>
                  </span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a class="dropdown-item" href="#!">
                  <span>
                    <i class="bi bi-gear-fill me-2"></i>
                    <span class="fs-7">Settings & Privacy</span>
                  </span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="!">
                  <span>
                    <i class="bi bi-question-circle-fill me-2"></i>
                    <span class="fs-7">{{ 'Navbar.HelpCenter' | translate }}</span>
                  </span>
                </a>
              </li>
              <li>
                <div class="dropdown-item">
                  <span>
                    <i class="bi bi-translate me-2"></i>
                    <span class="fs-7">{{ 'Navbar.Languages' | translate }}</span>
                  </span>
                  <div class="language-switcher mt-2">
                    <button class="lang-btn" [ngClass]="{'active': currentLanguage === 'en'}"
                      (click)="switchLanguage('en')">{{ 'Navbar.English' | translate }}</button>
                    <button class="lang-btn" [ngClass]="{'active': currentLanguage === 'fr'}"
                      (click)="switchLanguage('fr')">{{ 'Navbar.French' | translate }}</button>
                  </div>
                </div>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a class="dropdown-item text-center" (click)="logout()">
                  <span>
                    <span class="fs-7">{{'Navbar.LogOut' | translate}}</span>
                  </span>
                </a>
              </li>
            </ul>

          </div>



        </ng-container>
        <ng-template #guest>
          <div class="d-flex align-items-center">
            <!-- Language Switcher for Guests -->
            <div class="nav-item dropdown me-2">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="fi fi-{{ currentLanguage === 'en' ? 'gb' : 'fr' }}"></span>
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <button class="dropdown-item" (click)="switchLanguage('en')">
                    <span class="fi fi-gb"></span> {{ 'Navbar.English' | translate }}
                  </button>
                </li>
                <li>
                  <button class="dropdown-item" (click)="switchLanguage('fr')">
                    <span class="fi fi-fr"></span> {{ 'Navbar.French' | translate }}
                  </button>
                </li>
              </ul>
            </div>
            <!-- Sign Up Button -->
            <button class="button smallbutton" routerLink="/account/login">{{ 'Navbar.SignUp' | translate }}</button>
          </div>
        </ng-template>
      </div>



    </div>
  </nav>
</ng-container>
<ng-template #normalScreen>
  <nav class="navbar navbar-expand-md sticky-top bg-white shadow-sm" *ngIf="account?.role !== Role.Admin ">
    <div class="container-fluid">
      <!--  Button -->
      <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#bsbNavbar"
        aria-controls="bsbNavbar" aria-label="Toggle Navigation">
        <span class="navbar-toggler-icon"></span>
      </button>


      <!-- branding -->
      <a class="navbar-brand" routerLink="/">
        <div class="d-flex align-items-center">
          <img [src]="'assets/img/logo_12.png'" class="bsb-tpl-logo" alt="MegaLearnLogo">
          <h1 class="navbar-brand-text">mega<span class="hide-on-small">Learn</span></h1>
        </div>
      </a>



      <!-- Links -->
      <div class="collapse navbar-collapse justify-content-center" id="bsbNavbar">
        <ul class="navbar-nav bsb-dropdown-menu-responsive">
          <li class="nav-item">
            <a routerLink="/" class="nav-link align-items-center styled-link">{{ 'Navbar.Home' | translate }}</a>
          </li>
          <li class="nav-item">
            <a routerLink="courses/all" class="nav-link align-items-center styled-link">{{ 'Navbar.Courses' | translate
              }}</a>
          </li>
          <li class="nav-item">
            <a routerLink="courses/mycourses" class="nav-link align-items-center styled-link" *ngIf="account">{{
              'Navbar.MyLearning' | translate }}</a>
          </li>
          <li class="nav-item">
            <a routerLink="courses/panel" class="nav-link align-items-center styled-link"><i class="bi bi-cart"></i></a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{ 'Navbar.Explore'| translate }}
            </a>
            <ul class="dropdown-menu">
              <li *ngFor="let category of categories">
                <a class="dropdown-item" [routerLink]="['/courses/category', category._id]"
                  (click)="selectCategory(category._id)">
                  {{ category.name }}
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div class="search-container ms-3 mb-2" (click)="stopPropagation($event)">
          <!-- Search button-->
          <div class="search-box" *ngIf="!isSmallScreen">
            <form (ngSubmit)="onSearch(searchInput.value)">
              <button class="btn-search" type="submit"><i class="bi bi-search"></i></button>
              <input #searchInput type="search" class="input-search" placeholder="{{ 'Navbar.Search' | translate }}"
                (input)="onSearchInput(searchInput.value)">
            </form>
          </div>

          <!-- Suggestions List -->
          <div *ngIf="showSuggestions && searchResults.length" class="suggestions-list">
            <ul class="list-group">
              <li *ngFor="let course of searchResults" class="list-group-item" (click)="selectSuggestion(course)">
                {{ course.title }}
              </li>
            </ul>
          </div>
        </div>


      </div>

      <!-- account Section -->
      <div class="navbar-profile">

        <ng-container *ngIf="account; else guest">
          <div class="nav-item dropdown">
            <a class="nav-link dropdown-toggle bsb-dropdown-toggle-caret-disable" href="#" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img [src]="account.image || 'assets/img/pdp.jpg'" width="45" height="45" class="rounded-circle"
                style="object-fit: cover;" alt="Profile Pic">
            </a>
            <ul class="dropdown-menu dropdown-menu-md-end bsb-dropdown-animation bsb-fadeIn custom-dropdown-menu">
              <li>
                <h6 class="dropdown-header fs-7 text-center">{{'Navbar.Welcome' | translate}}, {{account.firstName}}
                </h6>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a routerLink="/profile" class="dropdown-item" aria-current="true">
                  <div class="row g-0 align-items-center">
                    <div class="col-4 ">
                      <img [src]="account.image || 'assets/img/pdp.jpg'" width="55" height="55" class="rounded-circle"
                        style="object-fit: cover;" alt="profile picture">
                    </div>
                    <div class="col-8">
                      <div class="ps-1">
                        <div class="text-secondary mt-1 fs-7">{{ account.username }}</div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a class="dropdown-item" routerLink="/profile">
                  <span>
                    <i class="bi bi-person-fill me-2"></i>
                    <span class="fs-7">{{'Navbar.ViewProfile' | translate}}</span>
                  </span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="courses/certificate">
                  <span>
                    <i class="bi bi-award me-2"></i>
                    <span class="fs-7">{{'Navbar.Certificates'| translate}}</span>
                  </span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a class="dropdown-item" href="#!">
                  <span>
                    <i class="bi bi-gear-fill me-2"></i>
                    <span class="fs-7">{{ 'Navbar.Settings&Privacy' | translate }}</span>
                  </span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="!">
                  <span>
                    <i class="bi bi-question-circle-fill me-2"></i>
                    <span class="fs-7">{{ 'Navbar.HelpCenter' | translate }}</span>
                  </span>
                </a>
              </li>
              <li>
                <div class="dropdown-item">
                  <span>
                    <i class="bi bi-translate me-2"></i>
                    <span class="fs-7">{{ 'Navbar.Languages' | translate }}</span>
                  </span>
                  <div class="language-switcher mt-2">
                    <button class="lang-btn" [ngClass]="{'active': currentLanguage === 'en'}"
                      (click)="switchLanguage('en')">{{ 'Navbar.English' | translate }}</button>
                    <button class="lang-btn" [ngClass]="{'active': currentLanguage === 'fr'}"
                      (click)="switchLanguage('fr')">{{ 'Navbar.French' | translate }}</button>
                  </div>
                </div>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a class="dropdown-item text-center" (click)="logout()">
                  <span>
                    <span class="fs-7">{{ 'Navbar.LogOut' | translate }}</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-template #guest>
          <div class="d-flex align-items-center">
            <!-- Language Switcher for Guests -->
            <div class="nav-item dropdown me-2">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="fi fi-{{ currentLanguage === 'en' ? 'gb' : 'fr' }}"></span>
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <button class="dropdown-item" (click)="switchLanguage('en')">
                    <span class="fi fi-gb"></span> {{ 'Navbar.English' | translate }}
                  </button>
                </li>
                <li>
                  <button class="dropdown-item" (click)="switchLanguage('fr')">
                    <span class="fi fi-fr"></span> {{ 'Navbar.French' | translate }}
                  </button>
                </li>
              </ul>
            </div>
            <!-- Sign Up Button -->
            <button class="button smallbutton" routerLink="/account/login">{{ 'Navbar.SignUp' | translate }}</button>
          </div>
        </ng-template>
      </div>
    </div>
  </nav>
</ng-template>