<div class="search-container" (click)="$event.stopPropagation()">
  <div class="search-box">
    <form (ngSubmit)="onSearch(searchInput.value)">
      <button class="btn-search" type="submit"><i class="bi bi-search"></i></button>
      <input #searchInput 
      type="search" 
      [ngClass]="{'input-search': !isSmallScreen, 'input-search-small': isSmallScreen}"
      placeholder="{{ 'Navbar.Search' | translate }}" 
      (input)="onSearchInput(searchInput.value)">
    </form>
  </div>

  <div *ngIf="showSuggestions && searchResults.length" class="suggestions-list">
    <ul class="list-group">
      <li *ngFor="let course of searchResults" class="list-group-item" (click)="selectSuggestion(course)">
        {{ course.title }}
      </li>
    </ul>
  </div>
</div>