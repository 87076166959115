import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchPipe } from './pipes/search.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AlertComponent, FooterComponent, NavbarComponent } from './components';
import { RouterModule } from '@angular/router';
import { ListComponent } from './components/list';
import { BreadcrumbComponent } from './components/breadcrumb';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselComponent } from './components/carousel/carousel.component';
import { WelcomeHomeComponent } from './components/welcome-home/welcome-home.component';
import { ServiceHomeComponent } from './components/service-home/service-home.component';
import { CategoryHomeComponent } from './components/category-home/category-home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TimerNavbarComponent } from './components/timer-navbar/timer-navbar.component';
import { SearchNavbarComponent } from './components/search-navbar/search-navbar.component';
import { ProfileNavbarComponent } from './components/profile-navbar/profile-navbar.component';
import { NavComponent } from './components/nav/nav.component';

@NgModule({
  declarations: [
    AlertComponent,
    NavbarComponent,
    FooterComponent,
    BreadcrumbComponent,
    ListComponent,
    SearchPipe,
    TruncatePipe,
    CarouselComponent,
    WelcomeHomeComponent,
    ServiceHomeComponent,
    CategoryHomeComponent,
    NotFoundComponent,
    TimerNavbarComponent,
    SearchNavbarComponent,
    ProfileNavbarComponent,
    NavComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    AlertComponent,
    NavbarComponent,
    FooterComponent,
    BreadcrumbComponent,
    ListComponent,
    SearchPipe,
    TruncatePipe,
    TranslateModule,
    CarouselComponent,
    WelcomeHomeComponent,
    ServiceHomeComponent,
    CategoryHomeComponent,
    NavComponent
  ]
})
export class SharedModule { }
