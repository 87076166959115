import { Component } from '@angular/core';
import { Category } from '@app/_models/category';
import { CategoriesService } from '@app/_services';

@Component({
  selector: 'app-category-home',
  templateUrl: './category-home.component.html',
  styleUrls: ['./category-home.component.css']
})
export class CategoryHomeComponent {
  categories: Category[] = [];
  searchTerm: string = '';
  filteredCategories: Array<any> = this.categories;
  constructor(private categoriesService: CategoriesService) {}

  ngOnInit(): void {
    // Fetch categories from the backend
    this.fetchCategories();
  }

  /**
   * Fetch categories from the backend.
   */
  fetchCategories(): void {
    this.categoriesService.getAll().subscribe(
      (categories) => {
        this.categories = categories;
        this.filteredCategories = categories; // Initialize filteredCategories with all categories
      },
      (error) => {
        console.error('Failed to fetch categories:', error);
      }
    );
  }

  /**
   * Filter categories based on the search term.
   */
  filterCategories(): void {
    this.filteredCategories = this.categories.filter((category) =>
      category.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  getLen(c : []){
    return c.length
  }
}
