<!-- Welcome Section -->
<section class="welcome-section">
    <div class="welcome-container">
      <div class="welcome-content">
        <div class="welcome-text">
          <h1 class="welcome-title">{{ 'Home.WelcomeMessage' | translate }}</h1>
          <p class="welcome-description">{{ 'Home.Introduction' | translate }}</p>
          <div class="welcome-features">
            <div class="feature-item">
              <i class="feature-icon fas fa-arrow-right"></i>
              <p class="feature-text">{{ 'Home.OnlineCourses' | translate }}</p>
            </div>
            <div class="feature-item">
              <i class="feature-icon fas fa-arrow-right"></i>
              <p class="feature-text">{{ 'Home.ShareableCertificates' | translate }}</p>
            </div>
          </div>
          <button class="welcome-button" routerLink="account/login">{{ 'Home.JoinNow' | translate }}</button>
        </div>
      </div>
    </div>
  </section>