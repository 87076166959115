import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Account, Role } from '@app/_models';
import { AccountService, DiscountTimerService, ScreenSizeService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {
  @ViewChild('navbarCollapse') navbarCollapse!: ElementRef;
  currentLanguage: string = 'fr';

  Role = Role;
  account?: Account | null;
  isSmallScreen: boolean = false;
  timeLeft$: Observable<number>;
  showTimer: boolean = true;
  timeLeftValue: number | null = null;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private screenSizeService: ScreenSizeService,
    private discountTimerService: DiscountTimerService,
    private translate: TranslateService
  ) {
    this.timeLeft$ = this.discountTimerService.timeLeft;
  }

  ngOnInit(): void {
    this.accountService.account.subscribe((x) => (this.account = x));
    this.screenSizeService.isSmallScreen().subscribe((isSmall) => {
      this.isSmallScreen = isSmall;
    });

    this.timeLeft$.subscribe((timeLeft) => {
      this.timeLeftValue = timeLeft;
    });

    const savedLanguage = localStorage.getItem('language') || 'fr';
    this.translate.use(savedLanguage);
  }

  isSidebarOpen = false;

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  closeNavbar(): void {
    this.isSidebarOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.navbar') && !target.closest('.navbar-toggler')) {
      this.closeNavbar();
    }
  }

  // debug
  hideTimer(): void {
    this.showTimer = false; // Assuming `showTimer` is a property in your component
  }

  onSearch(searchTerm: string): void {
    this.router.navigate(['/courses'], { queryParams: { search: searchTerm } });
  }
  switchLanguage(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.currentLanguage = lang;
  }
}
