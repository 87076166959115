<!-- Services Highlight Start -->
<div class="services-highlight-container">
    <div class="services-highlight-inner">
        <!-- Section Title -->
        <div class="section-title">
            <h2 class="section-heading">Our Services</h2>
            <p class="section-subheading">Discover what we offer to help you achieve your goals.</p>
        </div>
        <div class="services-grid">
            <div class="service-card wow fadeInUp" data-wow-delay="0.1s">
                <div class="service-content">
                    <i class="bi bi-mortarboard service-icon"></i>
                    <h5 class="service-title">{{ 'Home.BestOnlineCourses' | translate }}</h5>
                    <p class="service-description">{{ 'Home.BestOnlineCoursesDescription' | translate }}</p>
                </div>
            </div>
            <div class="service-card wow fadeInUp" data-wow-delay="0.3s">
                <div class="service-content">
                    <i class="bi bi-globe service-icon"></i>
                    <h5 class="service-title">{{ 'Home.WebinarOrganization' | translate }}</h5>
                    <p class="service-description">{{ 'Home.WebinarOrganizationDescription' | translate }}</p>
                </div>
            </div>
            <div class="service-card wow fadeInUp" data-wow-delay="0.5s">
                <div class="service-content">
                    <i class="bi bi-webcam service-icon"></i>
                    <h5 class="service-title">{{ 'Home.VideoConferenceClasses' | translate }}</h5>
                    <p class="service-description">{{ 'Home.VideoConferenceClassesDescription' | translate }}</p>
                </div>
            </div>
            <div class="service-card wow fadeInUp" data-wow-delay="0.7s">
                <div class="service-content">
                    <i class="bi bi-book service-icon"></i>
                    <h5 class="service-title">{{ 'Home.PrivateClasses' | translate }}</h5>
                    <p class="service-description">{{ 'Home.PrivateClassesDescription' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Highlight End -->