<ng-container *ngIf="account; else guest">
    <!-- Profile Photo with Hover Effect -->
    <div class="profile-photo" (click)="toggleSidebar()">
      <img [src]="account.image || 'assets/img/pdp.jpg'" width="50" height="50" class="rounded-circle" style="object-fit: cover;" alt="Profile Pic">
    </div>

    <!-- Backdrop (for closing sidebar when clicking outside) -->
    <div class="backdrop" *ngIf="isSidebarOpen" (click)="toggleSidebar()"></div>
  
    <!-- Sidebar -->
    <div class="sidebar" [class.open]="isSidebarOpen">
      <!-- Close Button -->
      <button class="close-btn" (click)="toggleSidebar()">
        <i class="bi bi-x-lg"></i>
      </button>
  
      <!-- Sidebar Content -->
      <div class="sidebar-content">
  
        <!-- Profile Section -->
        <a routerLink="/profile" class="sidebar-item" (click)="toggleSidebar()">
          <div class="row g-0 align-items-center">
            <div class="col-3">
              <img [src]="account.image || 'assets/img/pdp.jpg'" width="55" height="55" class="rounded-circle" style="object-fit: cover;" alt="profile picture">
            </div>
            <div class="col-9">
              <div class="ps-3">
                <div class="text-secondary ms-1 mt-1 fs-7">{{ account.username }}</div>
              </div>
            </div>
          </div>
        </a>
        <hr class="sidebar-divider">
  
        <!-- Menu Items -->
        <a class="sidebar-item" routerLink="/profile" (click)="toggleSidebar()">
          <i class="bi bi-person-fill me-2"></i>
          <span class="fs-7">{{'Navbar.ViewProfile' | translate}}</span>
        </a>
        <a class="sidebar-item" routerLink="courses/certificate" (click)="toggleSidebar()">
          <i class="bi bi-award me-2"></i>
          <span class="fs-7">{{'Navbar.Certificates'| translate}}</span>
        </a>
        <hr class="sidebar-divider">
  
        <a class="sidebar-item" href="#!" (click)="toggleSidebar()">
          <i class="bi bi-gear-fill me-2"></i>
          <span class="fs-7">Settings & Privacy</span>
        </a>
        <a class="sidebar-item" href="!" (click)="toggleSidebar()">
          <i class="bi bi-question-circle-fill me-2"></i>
          <span class="fs-7">{{ 'Navbar.HelpCenter' | translate }}</span>
        </a>
  
        <!-- Language Toggle -->
        <div class="sidebar-item">
          <div class="d-flex align-items-center">
            <i class="bi bi-translate me-2"></i>
            <span class="fs-7">{{ 'Navbar.Languages' | translate }}</span>
          </div>
          <div class="language-toggle mt-2">
            <button class="lang-btn" [ngClass]="{'active': currentLanguage === 'en'}" (click)="switchLanguage('en')">EN</button>
            <button class="lang-btn" [ngClass]="{'active': currentLanguage === 'fr'}" (click)="switchLanguage('fr')">FR</button>
          </div>
        </div>
        <hr class="sidebar-divider">
  
        <!-- Logout -->
        <a class="sidebar-item text-center" (click)="logout(); toggleSidebar()">
          <span class="fs-7">{{'Navbar.LogOut' | translate}}</span>
        </a>
      </div>
    </div>
  </ng-container>
  
  <!-- Guest Template -->
  <ng-template #guest>
    <div class="d-flex align-items-center">
      <!-- Language Toggle for Guest -->
      <div class="language-toggle me-2" *ngIf="!isSmallScreen">
        <button class="lang-btn" [ngClass]="{'active': currentLanguage === 'en'}" (click)="switchLanguage('en')">EN</button>
        <button class="lang-btn" [ngClass]="{'active': currentLanguage === 'fr'}" (click)="switchLanguage('fr')">FR</button>
      </div>
      <button class="button smallbutton" routerLink="/account/login" *ngIf="!isSmallScreen">{{ 'Navbar.SignUp' | translate }}</button>
      <button class="icon-button" routerLink="/account/login" *ngIf="isSmallScreen">
        <i class="bi bi-person"></i>
      </button>
    </div>
  </ng-template>