import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from '@app/_models';
import { AccountService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-navbar',
  templateUrl: './profile-navbar.component.html',
  styleUrls: ['./profile-navbar.component.css']
})
export class ProfileNavbarComponent {
  @Input() account?: Account | null;
  currentLanguage: string = 'fr';
  @Input() isSmallScreen: boolean = false;

  isSidebarOpen = false;

  constructor(
    private router: Router, 
    private translate: TranslateService,
    private accountService: AccountService
    ) {}

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  switchLanguage(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.currentLanguage = lang;
  }

  /**
   * Logs the user out by invoking the account service.
   */
  logout() {
    this.accountService.logout();
  }
}
