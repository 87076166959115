<!-- Navbar  -->
<!-- <app-navbar *ngIf="account?.role !== Role.Admin "></app-navbar>  -->
 <app-nav *ngIf="account?.role !== Role.Admin "></app-nav>

 
  <!-- global alert -->
  <alert></alert>


  <!-- main router outlet -->
  <router-outlet></router-outlet>

<!-- Footer -->
<app-footer></app-footer>