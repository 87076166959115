<app-timer-navbar *ngIf="showTimer && timeLeftValue !== null && !isSidebarOpen" [timeLeftValue]="timeLeftValue"
  (hideTimerEvent)="hideTimer()"></app-timer-navbar>

  <ng-container *ngIf="isSmallScreen; else normalScreen">
    <nav class="navbar navbar-expand-md sticky-top bg-white shadow-sm">
      <div class="container-fluid">
        <!-- Toggle Button for Sidebar -->
        <button class="navbar-toggler border-0" type="button" (click)="toggleSidebar()" aria-label="Toggle Sidebar">
          <i class="bi toggle-icon" [class.bi-chevron-down]="!isSidebarOpen" [class.bi-chevron-left]="isSidebarOpen"></i>
        </button>
  
        <!-- Logo -->
        <a class="navbar-brand" routerLink="/">
          <div class="d-flex align-items-center">
            <img src="assets/img/logo_12.png" alt="MegaLearnLogo" class="logo">
          </div>
        </a>
  
        <!-- Account Section (Outside Sidebar) -->
        <app-profile-navbar [account]="account" [isSmallScreen]="isSmallScreen"></app-profile-navbar>
      </div>
    </nav>
  
    <!-- Sidebar -->
    <div class="sidebar" [class.open]="isSidebarOpen">
      <!-- Close Button -->
      <button class="close-btn" (click)="toggleSidebar()" aria-label="Close Sidebar">
        <i class="bi bi-x-lg"></i>
      </button>
  
      <!-- Sidebar Content -->
      <div class="sidebar-content">
        <!-- Search Bar at the Top -->
        <div class="sidebar-search">
          <app-search-navbar (searchEvent)="onSearch($event)" (suggestionSelected)="toggleSidebar()" [isSmallScreen]="isSmallScreen" ></app-search-navbar>
        </div>
  
        <!-- Vertical List of Links -->
        <ul class="sidebar-nav">
          <li class="nav-item">
            <a routerLink="/home" class="nav-link styled-link" routerLinkActive="active" (click)="toggleSidebar()">
              {{ 'Navbar.Home' | translate }}
              <span class="underline"></span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="courses/all" class="nav-link styled-link" routerLinkActive="active" (click)="toggleSidebar()">
              {{ 'Navbar.Courses' | translate }}
              <span class="underline"></span>
            </a>
          </li>
          <li class="nav-item" *ngIf="account">
            <a routerLink="courses/mycourses" class="nav-link styled-link" routerLinkActive="active" (click)="toggleSidebar()">
              {{ 'Navbar.MyLearning' | translate }}
              <span class="underline"></span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="courses/panel" class="nav-link styled-link" routerLinkActive="active" (click)="toggleSidebar()">
              <i class="bi bi-cart"></i>
              <span class="underline"></span>
            </a>
          </li>
        </ul>
        <!-- Language Toggle for Guest (Visible in Sidebar on Small Screens) -->
    <div class="sidebar-item" *ngIf="!account">
      <!-- <div class="d-flex align-items-center">
        <i class="bi bi-translate me-2"></i>
        <span class="fs-7">{{ 'Navbar.Languages' | translate }}</span>
      </div> -->
      <div class="language-toggle mt-2">
        <button class="lang-btn" [ngClass]="{'active': currentLanguage === 'en'}" (click)="switchLanguage('en')">EN</button>
        <button class="lang-btn" [ngClass]="{'active': currentLanguage === 'fr'}" (click)="switchLanguage('fr')">FR</button>
      </div>
    </div>
      </div>
    </div>
  
    <!-- Backdrop (for closing sidebar when clicking outside) -->
    <div class="backdrop" *ngIf="isSidebarOpen" (click)="toggleSidebar()"></div>
  </ng-container>

<ng-template #normalScreen>
  <nav class="navbar navbar-expand-md sticky-top bg-white shadow-sm" *ngIf="account?.role !== Role.Admin">
    <div class="container-fluid">
      <!-- Toggle button -->
      <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#bsbNavbar"
        aria-controls="bsbNavbar" aria-label="Toggle Navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Logo -->
      <a class="navbar-brand" routerLink="/">
        <div class="d-flex align-items-center">
          <img src="assets/img/logo_12.png" alt="MegaLearnLogo" class="logo">
        </div>
      </a>

      <!-- Link List -->
      <div class="d-flex">
        <div class="d-flex align-items-center w-100">
          <!-- Link List -->
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a routerLink="/home" class="nav-link styled-link" routerLinkActive="active" (click)="closeNavbar()">
                {{ 'Navbar.Home' | translate }}
                <span class="underline"></span>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="courses/all" class="nav-link styled-link" routerLinkActive="active"
                (click)="closeNavbar()">
                {{ 'Navbar.Courses' | translate }}
                <span class="underline"></span>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="courses/mycourses" class="nav-link styled-link" routerLinkActive="active" *ngIf="account"
                (click)="closeNavbar()">
                {{ 'Navbar.MyLearning' | translate }}
                <span class="underline"></span>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="courses/panel" class="nav-link styled-link" routerLinkActive="active"
                (click)="closeNavbar()">
                <i class="bi bi-cart"></i>
                <span class="underline"></span>
              </a>
            </li>
          </ul>
          <!-- Search Bar -->
          <!-- <app-search-navbar (searchEvent)="onSearch($event)"></app-search-navbar> -->
        </div>
        <div class="search-container">
          <app-search-navbar (searchEvent)="onSearch($event)" [isSmallScreen]="isSmallScreen"></app-search-navbar>
        </div>
      </div>
      <!-- Account Section -->
      <app-profile-navbar [account]="account" [isSmallScreen]="isSmallScreen"></app-profile-navbar>
    </div>
  </nav>
</ng-template>