<!-- Course List Section -->
<section class="course-list-section">
  <div class="course-list-container">
    <div class="course-list-header">
      <h6 class="course-list-subtitle">{{ 'Courses' | translate }}</h6>
      <h1 class="course-list-title">{{ 'Shared.list.OurBestCourses' | translate }}</h1>
    </div>
    <div class="course-list-grid">
      <div class="course-card" *ngFor="let c of displayedCourses">
        <div class="course-card-image" (click)="goto(c._id)">
          <img [src]="c.image" alt="{{ c.title }}" class="course-image">
        </div>
        <div class="course-card-body">
          <div class="course-card-header">
            <span class="course-category">{{ c.category.name }}</span>
          </div>
          <h3 class="course-title">
            <a routerLink="/courses/details/{{ c._id }}">{{ c.title }}</a>
          </h3>
          <div class="course-meta">
            <span class="course-duration">
              <i class="bi bi-clock"></i> {{ c.duration }} {{ 'Hours' | translate }}
            </span>
            <span class="course-rating">
              <i class="bi bi-star"></i> 4.3
            </span>
            <span class="course-enrollments">
              <i class="bi bi-person"></i> {{ c.randomNumber }}
            </span>
          </div>
          <div class="course-action">
            <button class="course-button" routerLink="/courses/details/{{ c._id }}">
              <i class="bi bi-cart"></i> {{ discount(c.price) }} TND
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="course-list-footer">
      <button class="course-more-button" routerLink="/courses/all">
        {{ 'More Courses' | translate }}
        <i class="bi bi-arrow-right"></i>
      </button>
    </div>
  </div>
</section>