<footer id="footer" class="footer position-relative" *ngIf="account?.role !== Role.Admin || false">
  <div class="container footer-top">
    <div class="row gy-4">
      <!-- About Section -->
      <div class="col-lg-4 col-md-6 footer-about text-center text-md-start">
        <a routerLink="/" class="logo d-inline-block mb-3">
          <img [src]="'assets/img/logo_12.png'" alt="MegaLearn Logo" class="img-fluid">
        </a>
        <div class="footer-contact">
          <p>{{ 'Footer.MegaLearnDescription' | translate }}</p>
          <p><strong>{{ 'Footer.Email' | translate }}</strong>: <span>{{ 'Footer.EmailValue' | translate }}</span></p>
        </div>
        <div class="social-links d-flex justify-content-center justify-content-md-start mt-4">
          <a routerLink="/" class="me-3"><i class="bi bi-twitter-x"></i></a>
          <a routerLink="/" class="me-3"><i class="bi bi-facebook"></i></a>
          <a routerLink="/" class="me-3"><i class="bi bi-instagram"></i></a>
          <a routerLink="/"><i class="bi bi-linkedin"></i></a>
        </div>
      </div>

      <!-- Useful Links Section -->
      <div class="col-lg-2 col-md-3 footer-links text-center text-md-start">
        <h4>{{ 'Footer.UsefulLinks' | translate }}</h4>
        <ul>
          <li><a routerLink="/">{{ 'Footer.Link.Home' | translate }}</a></li>
          <li><a routerLink="courses/all">{{ 'Footer.Link.Courses' | translate }}</a></li>
          <li><a routerLink="claim">{{ 'Footer.Link.Claim' | translate }}</a></li>
          <li><a routerLink="/">{{ 'Footer.Link.Terms' | translate }}</a></li>
          <li><a routerLink="/">{{ 'Footer.Link.Privacy' | translate }}</a></li>
        </ul>
      </div>

      <!-- Newsletter Section -->
      <div class="col-lg-4 col-md-12 footer-newsletter text-center text-md-start">
        <h4>{{ 'Footer.Newsletter' | translate }}</h4>
        <p>{{ 'Footer.NewsletterDescription' | translate }}</p>
        <form action="forms/newsletter.php" method="post" class="php-email-form">
          <div class="newsletter-form d-flex flex-column flex-md-row gap-2">
            <input type="email" name="email" class="form-control" placeholder="{{ 'Email' | translate }}" required>
            <input type="submit" value="{{ 'Footer.Subscribe' | translate }}" class="btn btn-primary">
          </div>
          <div class="loading mt-2">{{ 'Footer.Loading' | translate }}</div>
          <div class="error-message mt-2">{{ 'Footer.ErrorMessage' | translate }}</div>
          <div class="sent-message mt-2">{{ 'Footer.SentMessage' | translate }}</div>
        </form>
      </div>
    </div>
  </div>

  <!-- Copyright Section -->
  <div class="container copyright text-center py-4">
    <p><span>{{ 'Footer.Copyright' | translate }}</span> <strong class="px-1 sitename">iPro</strong> <span>{{ 'Footer.AllRightsReserved' | translate }}</span></p>
  </div>
</footer>