<div class="not-found-container">
    <div class="not-found-content text-center">
      <!-- Big Icon -->
      <div class="not-found-icon">
        <i class="bi bi-emoji-dizzy-fill"></i> 
      </div>
  
      <!-- Funny Title -->
      <h1 class="not-found-title">{{ 'NotFound.Title' | translate }}</h1>
  
      <!-- Funny Description -->
      <p class="not-found-description">
        {{ 'NotFound.Description' | translate }}
      </p>
  
      <!-- Call to Action -->
      <div class="not-found-actions">
        <a routerLink="/" class="btn btn-primary">
          <i class="bi bi-house-door-fill"></i> {{ 'NotFound.TakeMeHome' | translate }}
        </a>
      </div>
    </div>
  </div>